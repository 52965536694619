export const isIncludes = (item1: string, item2: string) => {
  return item1.includes(item2);
};

export const isValidUrl = (input: string) => {
  try {
    new URL(input);
    // URI has a scheme and a host
    return true;
  } catch (e) {
    // Malformed URI
    return false;
  }
};
