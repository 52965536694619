/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/learner-component.scss";
import Assessment from "../../components/Assessment";
import Announcement from "../../components/Announcement";
import ActivityTimeline from "../../components/ActivityTimeline";
import { getLearningJourney, getCourseActivity } from "../../axios/api";
import { StoreContext } from "../../data/store";
import CarouselPanel from "../../components/LearnerCarouselPanel";
import Select from "react-select";

const getConfigurableProps = () => ({
  autoPlay: false,
  showThumbs: false,
  showIndicators: false,
});

export const getTheCurrentPanel = (panelDateArray: string[]): number => {
  const dateNow = new Date(Date.now());
  dateNow.setDate(dateNow.getDate());
  dateNow.setHours(0,0,0,0);
  let panelArrSortable = panelDateArray.map((date: string, index: number)=>{
    const dates =  new Date(date);
    dates.setHours(0,0,0,0);
    if(dates.getTime()<=dateNow.getTime()){
      return {date,index};
    }else{
      return null;
    }
  }).filter(function( element ) {
    return element !== null
  });
  panelArrSortable.sort((a,b)=>{
    let aDate = new Date(a!.date);
    aDate.setHours(0,0,0,0);
    let bDate = new Date(b!.date);
    bDate.setHours(0,0,0,0);
    if(aDate<bDate){ return -1;}
    else if(aDate>bDate) {return 1;}
    else{
      return b!.index-a!.index
    }
  })
  return panelArrSortable.at(-1)?.index ?? 0;
};

const Learner: FC<{ learningPreview?: boolean }> = ({ learningPreview }) => {
  const [generateDashboard, setDashboard] = useState<boolean>(false);
  const [serverMessage, setMessage] = useState<string>("");
  const [panelIndex, setPanelIndex] = useState<number>(0);
  const [isLoading, setLoading] = useState(true);
  const [learningJourneyItems, setLearningJourneyItems] = useState<any>([]);
  const [courseActivityTimeline, setCourseActivityTimeline] = useState<any>([]);
  const [panelOptions, setPanelOptions] = useState<{label: string, value: number}[]>([]);
  const[errorOccured, setErrorOccured] = useState<boolean>(true);

  const { state } = useContext<any>(StoreContext);
  const user = useMemo(() => {
    return state?.userState ?? {};
  }, [state]);

  const sortArrayData = (data: any) => {
    data.map(
      (data: any) =>
      (data.panels = data.panels.filter((panel: any) => {
        return panel.is_published !== false;
      })).sort((a: any, b: any) => a.panel_order - b.panel_order),
    );
  };
  const fetchLearningJourney = async () => {
    try {
      const learningJourneyPayload = await getLearningJourney(user.course_id);
      sortArrayData(learningJourneyPayload);
      let panelWeekDateArray = learningJourneyPayload.map(
        (learningJourney: any) =>
          learningJourney.panels.map((panel: any) => {
            if(panel.is_published){
              return panel.panel_week_date;
            }else{
              return null;
            }
          }).filter(function( element: string ) {
            return element !== null
          })
      );
      let curr = new Date(); // get current date
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;
      let firstTimeLoading = 0;
      let firstday = new Date(curr.setDate(first));
      let lastday = new Date(curr.setDate(last));
      setLearningJourneyItems(learningJourneyPayload);
      if(learningJourneyPayload[0]?.panels?.length>0){
        const paneloptions = learningJourneyPayload.map(
          (learningJourney: any) =>
            learningJourney.panels.map((panel: any, index: number) => ({
              value: index,
              label: panel.panel_name,
            }))
        );
          setPanelOptions(paneloptions[0]);
      };
      setPanelIndex(getTheCurrentPanel(panelWeekDateArray[0]));
      if (
        (learningJourneyPayload[0].status === false ||
          learningJourneyPayload[0]?.status === undefined) &&
        learningPreview === undefined
      ) {
        setMessage(`Dashboard has not been generated yet!`);
      } else {
        setDashboard(true);
      }
      setLoading(false);
    } catch (err: any) {
      setMessage(err.message);
      setLoading(false);
    }
  };
  const fetchCourseActivityTimeline = async () => {
    try {
      const courseActivityTimelineData = await getCourseActivity(
        user.course_id,
      );
      setCourseActivityTimeline(courseActivityTimelineData);
      if (
        (courseActivityTimelineData.status === false ||
          courseActivityTimelineData?.status === undefined) &&
        learningPreview === undefined
      ) {
        setMessage(`Dashboard has not been generated yet!`);
      } else {
        setDashboard(true);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setMessage(err.message);
    }
  };

  const dataValidation = () => {
    if(user?.course_id === undefined){
      setErrorOccured(true);
    }
    else{
      setErrorOccured(false);
      fetchLearningJourney();
      fetchCourseActivityTimeline();
    }
  }

  useEffect(() => {
    dataValidation();
  }, []);

  return errorOccured?<h2>Please refresh (F5) the page, thank you.</h2> :(isLoading ? (
    <div role={"main"}>
      <h5>Please wait until redirection...</h5>{" "}
    </div>
  ) : !generateDashboard ? (
    <div role={"main"} data-testid="learner_message">
      <h5>{serverMessage}</h5>
    </div>
  ) : (
    <div
      role={"main"}
      data-testid="learner_parent"
      className="app-container text-left"
    >
      <div className="comp__container">
        <div className="component_row row">
          <div className="assessment__comp col col-lg-4">
            <Assessment />
          </div>
          <div className="announcement__comp col col-lg-8">
            <Announcement />
          </div>
        </div>
      </div>
      <div
        className={
          (learningJourneyItems[0]?.panels.length !== 0 &&
            learningJourneyItems[0]?.status) ||
          (learningJourneyItems[0]?.panels?.length > 0 && learningPreview)
            ? ""
            : "hidden"
        }
      >
        <div className="sm-m-20 l-my-30 mx-lg-auto">
          <div className="drop_Down_carousal">
            <div className="heading-section">
              <strong className="week-heading">Jump to</strong>
              <Select
                value={panelOptions.find((option) => option.value === panelIndex)}
                options = {panelOptions}
                className="dropdown_list_carousal"
                classNamePrefix={"dropdown_list"}
                isSearchable = {false}
                onChange={(option : any) => {
                  setPanelIndex(option.value);
                }}
              />
               
            </div>
          </div>
          <Carousel
            showStatus={false}
            emulateTouch={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={100}
            selectedItem={panelIndex>0? panelIndex:0}
            renderArrowPrev={(onClickHandler, hasPrev, label) => {
              const defStyle = { color: "white", cursor: "pointer" };
              const style = hasPrev
                ? { ...defStyle, color: "black", fill: "black" }
                : { ...defStyle, color: "grey", fill: "grey" };
              return (
                <div className="ca_prev">
                  <svg
                    tabIndex={0}
                    style={style}
                    onClick={(e) => {
                      setPanelIndex(
                        panelIndex > 0 ? panelIndex - 1 : panelIndex
                      );
                      onClickHandler();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPanelIndex(
                          panelIndex > 0 ? panelIndex - 1 : panelIndex
                        );
                        onClickHandler();
                      }
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="47"
                    height="47"
                    viewBox="0 0 47 47"
                  >
                    <use style={style}></use>
                    <path
                      id="next"
                      d="M14.352,45.153A23.5,23.5,0,0,1,23.5,0,23.492,23.492,0,0,1,40.117,40.117a23.525,23.525,0,0,1-25.764,5.036Zm.933-41.1A21.167,21.167,0,1,0,23.5,2.4,21.017,21.017,0,0,0,15.286,4.056Zm1.5,28.724V14.22a1,1,0,0,1,1.507-.861l15.736,9.28a1,1,0,0,1,0,1.723l-15.736,9.28a1,1,0,0,1-1.507-.861Z"
                      transform="translate(47 47) rotate(180)"
                    />
                  </svg>
                  <p
                    style={style}
                    onClick={(e) => {
                      setPanelIndex(
                        panelIndex > 0 ? panelIndex - 1 : panelIndex
                      );
                      onClickHandler();
                    }}
                    onKeyDown={(e) => {
                      setPanelIndex(
                        panelIndex > 0 ? panelIndex - 1 : panelIndex
                      );
                      onClickHandler();
                    }}
                  ></p>
                </div>
              );
            }}
            renderArrowNext={(onClickHandler, hasNext, label) => {
              const defStyle = { color: "white", cursor: "pointer" };
              const style = hasNext
                ? { ...defStyle, color: "black", fill: "black" }
                : { ...defStyle, color: "grey", fill: "grey" };
              return (
                <div className="ca_next">
                  <svg
                    tabIndex={0}
                    style={style}
                    onClick={(e) => {
                      setPanelIndex(
                        panelIndex < learningJourneyItems[0].panels.length - 1
                          ? panelIndex + 1
                          : panelIndex
                      );
                      onClickHandler();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPanelIndex(
                          panelIndex < learningJourneyItems[0].panels.length - 1
                            ? panelIndex + 1
                            : panelIndex
                        );
                        onClickHandler();
                      }
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="47.001"
                    height="47"
                    viewBox="0 0 47.001 47"
                  >
                    <path
                      id="next"
                      d="M14.353,45.153a23.513,23.513,0,0,1,0-43.306A23.506,23.506,0,0,1,32.647,45.153a23.576,23.576,0,0,1-18.294,0Zm.933-41.1A21.177,21.177,0,1,0,23.5,2.4,21.036,21.036,0,0,0,15.286,4.056Zm1.5,28.724V14.22a1,1,0,0,1,1.507-.861l15.736,9.28a1,1,0,0,1,0,1.723l-15.736,9.28a.99.99,0,0,1-.506.14A1,1,0,0,1,16.786,32.78Z"
                    />
                  </svg>
                  <p
                    style={style}
                    onClick={(e) => {
                      setPanelIndex(
                        panelIndex < learningJourneyItems[0].panels.length - 1
                          ? panelIndex + 1
                          : panelIndex
                      );

                      onClickHandler();
                    }}
                    onKeyDown={(e) => {
                      setPanelIndex(
                        panelIndex < learningJourneyItems[0].panels.length - 1
                          ? panelIndex + 1
                          : panelIndex
                      );
                      onClickHandler();
                    }}
                  ></p>
                </div>
              );
            }}
            {...getConfigurableProps()}
          >
            {learningJourneyItems.map((learningJourney: any) =>
              learningJourney.panels.map((panel: any, index: number) => {
                var edate;
                if (index < learningJourney.panels.length - 1) {
                  var nextPanelDate = new Date(
                    learningJourney.panels[index + 1].panel_week_date
                  );
                  var currPanelDate = new Date(panel.panel_week_date);
                  if (
                    new Date(currPanelDate.getTime() + 3600 * 1000 * 24) <
                    nextPanelDate
                  ) {
                    edate = new Date(
                      nextPanelDate.getFullYear(),
                      nextPanelDate.getMonth(),
                      nextPanelDate.getDate() - 1
                    );
                  } else {
                    //Do Nothing
                  }
                }
                return (
                  <CarouselPanel
                    panel={panel}
                    key={index}
                    user={user}
                    learningJourney={learningJourney}
                    endDate={edate}
                    selectedPanel={panelIndex}
                  />
                );
              })
            )}
          </Carousel>
        </div>
      </div>
      <div className={(courseActivityTimeline?.status) || (courseActivityTimeline?.Course_Activity_Panel?.length>0 && learningPreview)? "" : "hidden"}>
        <h1 className="learner_section__h1">Activity Overview</h1>
        <div className="l-mb-30">
          <ActivityTimeline activityTimeline={courseActivityTimeline} />
        </div>
      </div>
    </div>
  ))
};

export default Learner;
