/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Item } from "../interfaces/CourseActivity_Interfaces";
import { CourseActivityItem } from "./CourseActivityItem";
import AddingActivityTimeline from "./AddingActivityTimeline-config";
import { Modal } from "react-bootstrap";

import "../styles/CourseActivity-config.scss";

const CourseActivity: FC<Item> = (props) => {
  const [openActivityDialog, setOpenActivityDialog] = useState<boolean>(false);
  const [activityDialogPosition, setActivityDialogPosition] =
    useState<number>(0);

  const [panelItemId, setPanelItemId] = useState<number>(0);

  const [open, setOpen] = React.useState(false);
  const [enforceFocus, setEnforceFocus] = useState<boolean>(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (
      props.courseActivityTimelineData !== undefined 
    ) {
      // generateActivityBlocks(props);
      setOpenActivityDialog(false);
    }
  }, [props, activityDialogPosition, open]);
  const panelDataBlocks =
    props?.courseActivityTimelineData?.Course_Activity_Panel ?? [];

  panelDataBlocks.sort((a: any, b: any) => {
    return a.panel_id - b.panel_id;
  });

  const handleOnChange = (
    position: number,
    value: boolean,
    panelItemId: number
  ) => {
    setOpenActivityDialog(value);
    setOpen(true);
    setActivityDialogPosition(position);
    setPanelItemId(panelItemId);
    props.onChange();
  };

  const handleActivityPanelChange = (position: number, value: boolean) => {
    setOpenActivityDialog(value);
    setActivityDialogPosition(position);
    handleClose();
    props.onChange();
  };
  
  const ActivityBlocks = panelDataBlocks.map(
    (panelBlock: any, index: number) => {
      return (
        <li
          className="outer__box__2"
          id={panelBlock.panel_id + ""}
          key={`activity_block_${panelBlock.panel_id}`}
        >
          <CourseActivityItem
            title={panelBlock.panel_name}
            panel_id={panelBlock.panel_id}
            panelItems={panelBlock.Course_Activity_Panel_Item}
            onChange={handleOnChange}
            openActivityDialog={openActivityDialog}
          />
        </li>
      );
    }
  );
            
  return(
    <div key={`activitybody`}>
        <ul className="parent_outer_box_2">
        {ActivityBlocks}
        </ul>
        <div
          style={{ display: openActivityDialog ? "block" : "none" }}
          className="modal_form"
        >
          <Modal
            show={open}
            centered
            onHide={handleClose}
            backdrop="static"
            dialogClassName="modal_form"
            enforceFocus={enforceFocus}
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <AddingActivityTimeline
                panelData={props.courseActivityTimelineData}
                panelId={activityDialogPosition}
                onChange={handleActivityPanelChange}
                panelItemId={panelItemId}
                showRefreshError = {props.showRefreshError}
                setEnforceFocus = {(value: boolean) => {console.log('Value being set !',value);setEnforceFocus(value)}}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
  );
};
export default CourseActivity;
