import React, { FC, useEffect, useState } from "react";
import Calendar, { Detail } from "react-calendar";
import "react-calendar/dist/Calendar.css"; //away from code => less priority
import "../styles/datepicker.scss"; // more priority
import { ReactComponent as LeftArrow } from "../pages/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../pages/icons/rightArrow.svg";

const Date_picker: FC<{ date: any; onChange: Function }> = (Props) => {
  const [dateValue, setValue] = useState<Date | undefined>(Props.date);
  const [view, setView] = useState<Detail>("month");
  const [active, setActive] = useState<Date | undefined>(undefined);
  const setnewDate = (value: Date | string) => {
    Props.onChange(new Date(value));
  };
  useEffect(() => {
    setValue(Props.date);
  }, [Props.date]);
  return (
    <div>
      <Calendar
        onChange={(value: any, event: any) => {
          setValue(new Date(value));
          setnewDate(value);
        }}
        tileClassName={({ date, view }) => {
          if (date === dateValue) {
            return "active";
          } else {
            return "";
          }
        }}
        value={dateValue}
        next2Label={null}
        nextLabel={<RightArrow className="arrow_Icon" />}
        prevLabel={<LeftArrow className="arrow_Icon" />}
        prev2Label={null}
        minDate={new Date()}
        maxDate={new Date(new Date().getFullYear() + 7, 0, 0)}
        navigationLabel={({ date, label, locale, view }) => {
          var dateString = date.toDateString().split(" ");
          return `${dateString[1]}, ${dateString[3]}`;
        }}
        onDrillUp={({ activeStartDate, view }) => {
          if (view !== "century" && view !== "decade") {
            setView(view);
            setActive(undefined);
          } else if (view === "decade") {
            setView(view);
            setActive(
              new Date(
                new Date().getFullYear(),
                activeStartDate.getMonth(),
                activeStartDate.getDate()
              )
            );
          } else {
            setView(() => {
              return "decade";
            });
            setActive((active) => active);
          }
        }}
        onDrillDown={({ activeStartDate, view }) => {
          if (view !== "century") {
            setView(view);
            setActive(undefined);
          } else {
            setView(() => {
              return "decade";
            });
            setActive((active) => active);
          }
        }}
        view={view}
        activeStartDate={active}
      />
    </div>
  );
};
export default Date_picker;
