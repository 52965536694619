/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useMemo, useState, FC } from "react";
import axios from "../axios/config";
import { getSession } from "../utilities/querySession";
import { StoreContext } from "../data/store";


const stringManipforDate = (dueDate : string) => {
  return dueDate.toLowerCase().includes('to') ? dueDate.toLowerCase().replaceAll(/\s/g, "").replaceAll('-'," ").split('to')[0] : dueDate.toLowerCase().replaceAll(/\s/g, "").replaceAll('-'," ") ;
}
export const dateComparator = (a: any, b: any) => {
  if (a.dueDate === undefined && b.dueDate === undefined) {
    return 0;
  } else if (a.dueDate === undefined) {
    return 1;
  } else if (b.dueDate === undefined) {
    return -1;
  } else {
    let dueDateA = stringManipforDate(a.dueDate) ;
    let dueDateB = stringManipforDate(b.dueDate);
    const dateNow = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+1,0,0,0,0);
    if(new Date(dueDateA).getTime() <= dateNow.getTime() && new Date(dueDateB).getTime() <= dateNow.getTime()){
      return 0;
    }else if(new Date(dueDateA).getTime() <= dateNow.getTime()){
      return 1;
    }else if(new Date(dueDateB).getTime() <= dateNow.getTime()){
      return -1;
    }else if (new Date(dueDateA).getTime() >= new Date(dueDateB).getTime()) {
      return 1;
    } else {
      return -1;
    }
  }
};

const Assessment: FC<unknown> = () => {
  const { state } = useContext<any>(StoreContext);
  const [courseAssessments, setCourseAssessments] = useState([]);
  const user = useMemo(() => {
    return state?.userState ?? {};
  }, [state]);

  const stringManipforDate = (dueDate : string) => {
    return dueDate.toLowerCase().includes('to') ? dueDate.toLowerCase().replaceAll(/\s/g, "").replaceAll('-'," ").split('to')[1] : dueDate.toLowerCase().replaceAll(/\s/g, "").replaceAll('-'," ") ;
  }
  
  const dateComparator = (a: any, b: any) => {
    if (a.dueDate === undefined && b.dueDate === undefined) {
      return 0;
    } else if (a.dueDate === undefined) {
      return 1;
    } else if (b.dueDate === undefined) {
      return -1;
    } else {
      let dueDateA = stringManipforDate(a.dueDate) ;
      let dueDateB = stringManipforDate(b.dueDate);
      const dateNow = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+1,0,0,0,0);
      if(new Date(dueDateA).getTime() <= dateNow.getTime() && new Date(dueDateB).getTime() <= dateNow.getTime()){
        return 0;
      }else if(new Date(dueDateA).getTime() <= dateNow.getTime()){
        return 1;
      }else if(new Date(dueDateB).getTime() <= dateNow.getTime()){
        return -1;
      }else if (new Date(dueDateA).getTime() >= new Date(dueDateB).getTime()) {
        return 1;
      } else {
        return -1;
      }
    }
  };


  const fetchCourseAssessments = async () => {
    const response = await axios.get(
      `/api/instructor/getUserAssessments?userId=${user.user_id}&courseId=${user.course_id}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      },
    );
    const assessments = response.data;
    assessments.sort(dateComparator);
    setCourseAssessments(assessments);
  };
  useEffect(() => {
    fetchCourseAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let lAssessment: any = "";
  let listItems: any = [];
  let aData: boolean = false;
  courseAssessments.map((data: any, index) => {
    lAssessment = data.selfLink;
    aData = data.modified ? true : false;
  });
  if (aData) {
    listItems = courseAssessments.map((data: any, index) => (
      <li className="assessment__item list-group-item" key={index} id={data.id}>
        <div className="assessment-item__data">
          {data.dueDate && (
            <div className="assessment-item__date margin-block-none">
              {data.dueDate}
            </div>
          )}
          <a
            href={`${data.links[0].href}`}
            target="_blank"
            title={data.title}
            className="assessment-item__title text-truncate margin-block-none"
          >
            {data.title}
          </a>
        </div>
      </li>
    ));
  }
  return (
    <>
      <div className="Assessment" role={"banner"}>
        <div
          className="assessment__head d-flex justify-content-between align-items-center"
          role={"group"}
        >
          <h2 className="ms-2 Assessment-h2">Assessment</h2>
          <a href={lAssessment} target="_blank" className="view__all me-2">
            View All
          </a>
        </div>
        {aData ? (
          <ul className="Assessment___list list-group">{listItems}</ul>
        ) : (
          <p className="refer-message">
            Please refer to ECP or the course Assessment section
          </p>
        )}
      </div>
    </>
  );
};

export default Assessment;
