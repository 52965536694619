/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import axios from "../axios/config";
import "../styles/AddingActivityTimeline.scss";
import iconArray from "./ActivityIconPack";
import {
  TimelineData,
  CourseActivityPanelItem,
  IconStyleInterface,
  InputStyleInterface,
} from "../interfaces/CourseActivity_Interfaces";
import { ReactComponent as CalendarIcon } from "../pages/icons/calendarIcon.svg";
import DatePicker from "./datepicker";
import { DialogConfirmation } from "../pages/Instructor/dialog";
import buildDate from "./buildDate";
import { AlertColor } from "@mui/material";
import AlertWindow from "./Snackbar";
import { isValidUrl } from "../utilities/validators";
import { getSession } from "../utilities/querySession";

import { ReactComponent as CloseIcon } from "../assets/icons/x-circle.svg";

const AddingActivityTimeline: FC<TimelineData> = (props) => {
  const [activityName, setActivityName] = useState<string>("");
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [weighting, setWeighting] = useState<string>("");
  const [link, setLink] = useState<string | undefined>();
  const [description, setDescription] = useState<string>("");
  const [isShowDate, setIsShowDate] = useState<boolean>(false);
  const [isImportant, setIsImportant] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<string>("acquisition");
  const iconStyle: IconStyleInterface = {
    color: "black",
    height: "30px",
    width: "30px",
    stroke: "black",
    fill: "none",
  };

  const activityNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const activityTypeRef = useRef<HTMLDivElement>(null);

  const [activityNameInputStyle, setActivityNameInputStyleInputStyle] =
    useState<InputStyleInterface>({ border: "1px solid #707070" });
  const [descriptionInputStyle, setDescriptionInputStyle] =
    useState<InputStyleInterface>({
      border: "1px solid #707070",
    });

  const [dateString, setDateString] = useState<string>("");

  const [panelName, setPanelName] = useState<string>("");

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [openAlert, setAlertOpen] = useState<boolean>(false);

  const [popUpAlertOpen, setPopUpAlertOpen] = useState<boolean>(false);
  const [popUpAlert, setPopUpAlert] = useState<{
    context?: string;
    severity?: AlertColor;
  }>({ context: "", severity: "success" });

  const [isDisabled, setIsDisabled] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    let panelData = props.panelData.Course_Activity_Panel.find(
      (panel: any) => panel.panel_id === props.panelId,
    );

    if (panelData !== undefined) {
      setPanelName(panelData.panel_name);
      let panelItemData = panelData.Course_Activity_Panel_Item.find(
        (panelItem: any) => panelItem.panel_item_id === props.panelItemId,
      );

      if (panelItemData !== undefined) {
        setActivityName(panelItemData.activity_name);
        setActivityType(panelItemData.activity_type);
        setDueDate(
          panelItemData.due_date ? new Date(panelItemData.due_date) : null,
        );
        setWeighting(panelItemData.weighting);
        setLink(panelItemData.link);
        setDescription(panelItemData.description);
        setIsImportant(panelItemData.is_important);
        setIsDisabled(false);
      }
    }
    activityNameInputRef.current?.focus();
  }, [props]);

  useEffect(() => {
    setDateString(buildDate(dueDate!)!);
  }, [dueDate]);

  const handleCalenderChange = (value: Date) => {
    setDueDate(value);
    setIsShowDate((isShowDate) => !isShowDate);
  };

  const handleCalenderIcon = () => {
    setIsShowDate((isShowDate) => !isShowDate);
  };

  const handleOnClick = (value: string) => {
    setActivityType(value);
  };

  const handlePanelClose = (result: boolean) => {
    if (result) {
      deleteData();
    }
    props.setEnforceFocus(true);
    setOpenDialog(false);
  };

  const handleDescriptionInputChange = (event: any) => {
    if (event.target.value === undefined || event.target.value === "") {
      setDescriptionInputStyle({
        border: "1px solid #707070",
      });
    }
    if (event.target.value.length > 200) {
      setDescriptionInputStyle({
        border: "1px solid #FF0000",
      });
      setAlertOpen(true);
    }

    if (event.target.value.length <= 200) {
      setDescriptionInputStyle({
        border: "1px solid #707070",
      });
      setAlertOpen(false);
    }
    setDescription(event.target.value);
  };

  const handleDelete = () => {
    props.setEnforceFocus(false);
    setOpenDialog(!openDialog);
  };

  const deleteData = async () => {
    try {
      let result = (
        await axios.delete(
          `/api/instructor/deleteCourseActivityPanelItem?panel_item_id=${props.panelItemId}`,
          {
            headers: {
              Authorization: getSession("token"),
            },
          },
        )
      ).data;
      setActivityName("");
      setDueDate(null);
      setWeighting("");
      setLink("");
      setDescription("");
      setIsImportant(false);
      props.onChange(false, props.panelId);
    } catch (err) {
      setPopUpAlert({
        context: `Error in deleting panel item : ` + err,
        severity: `error`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const saveData = async () => {
    const isValid = isValidUrl(link as string);
    setIsValid(isValid);
    if (!isValid && link) {
      return;
    }

    if (checkCompulsoryFields()) {
      setIsDisabled(true);
      const data: CourseActivityPanelItem = {
        panel_item_id: props.panelItemId,
        panel_id: props.panelId,
        activity_type: activityType,
        activity_name: activityName,
        due_date: dueDate,
        weighting: weighting,
        link: link,
        description: description,
        is_important: isImportant,
      };
      try {
        const result = (
          await axios.put(
            `/api/instructor/storeCourseActivityPanelItem`,
            data,
            {
              headers: {
                Authorization: getSession("token"),
              },
            },
          )
        ).data;
        if (result?.panel_item_id === undefined) {
          setActivityName("");
          setDueDate(null);
          setWeighting("");
          setLink("");
          setDescription("");
          setIsImportant(false);
          setAlertOpen(false);
          props.showRefreshError();
          props.onChange(false, props.panelId);
        } else {
          setActivityName("");
          setDueDate(null);
          setWeighting("");
          setLink("");
          setDescription("");
          setIsImportant(false);
          setAlertOpen(false);
          props.onChange(false, props.panelId);
        }
      } catch (err) {
        setPopUpAlert({
          context: `Error in saving panel item : ` + err,
          severity: `error`,
        });
        setPopUpAlertOpen(true);
        setIsDisabled(false);
      }
    }
  };

  const checkCompulsoryFields = () => {
    if (description.trim().length > 0) {
      setDescriptionInputStyle({ border: "1px solid #707070" });
    }

    if (activityName.trim().length > 0) {
      setActivityNameInputStyleInputStyle({ border: "1px solid #707070" });
    }

    if (activityName.trim().length === 0) {
      setActivityNameInputStyleInputStyle({ border: "1px solid #FF0000" });
      activityNameInputRef.current?.classList.add("empty");
      if (description.trim().length === 0) {
        setDescriptionInputStyle({ border: "1px solid #FF0000" });
        descriptionInputRef.current?.classList.add("empty");
      }
      return false;
    }

    if (description.trim().length === 0) {
      setDescriptionInputStyle({ border: "1px solid #FF0000" });
      descriptionInputRef.current?.classList.add("empty");
      return false;
    }

    if (description.length > 200) {
      setAlertOpen(true);
      return false;
    }

    return true;
  };

  const handleAlertClose = () => {
    setPopUpAlertOpen(false);
  };

  return (
    <div className="outer_Container_Activity_timeline">
      <AlertWindow
        popUpAlertOpen={popUpAlertOpen}
        handleAlertClose={handleAlertClose}
        popUpAlert={popUpAlert}
      />
      <label className="panel_name">{panelName}</label>
      <hr className="horizontal_Line_Course_Activity_Timeline" />
      <div ref={activityTypeRef} className="add_Icons_Activity_Timeline">
        {iconArray.map((icon) => {
          return (
            <div
              className="icon_div"
              style={
                activityType === icon.name
                  ? {
                      border: "2px solid #51247a",
                      paddingTop: "5px",
                    }
                  : { color: "#000000" }
              }
            >
              <div
                style={
                  activityType === icon.name
                    ? {
                        color: "#51247a",
                        stroke: "#51247a",
                        height: "30px",
                        width: "30px",
                        fill: "none",
                      }
                    : iconStyle
                }
                className="icon_hover"
                onClick={() => handleOnClick(icon.name)}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    handleOnClick(icon.name);
                  }
                }}
              >
                <icon.iconName />
              </div>

              <label
                className={`activity_icon_label`}
                style={
                  activityType === icon.name
                    ? {
                        color: "#51247a",
                        fill: "#51247a",
                      }
                    : { color: "#000000" }
                }
                onClick={() => handleOnClick(icon.name)}
              >
                {icon.name.charAt(0).toUpperCase() + icon.name.slice(1)}
              </label>
            </div>
          );
        })}
      </div>
      <hr className="horizontal_Line_Course_Activity_Timeline" />
      <div className="container_input">
        <div className="Add_Activity_Input_boxes">
          <label className="activity_Name">
            Activity Name{" "}
            <sup>
              <b>*</b>
            </sup>
          </label>
          <br />
          <input
            type="text"
            ref={activityNameInputRef}
            style={activityNameInputStyle}
            id="activity_name"
            name="activity_name"
            className="form-control"
            defaultValue={activityName}
            onInput={(event: any) => {
              if (activityName === undefined || activityName === "") {
                setActivityNameInputStyleInputStyle({
                  border: "1px solid #707070",
                });
              }
              setActivityName(event.target.value);
            }}
          />
          <br />
          <label className="due_date">Due Date (optional)</label>
          <br />
          <div className="display_calender">
            <div>
              <CalendarIcon
                className="calender_icon"
                onClick={() => {
                  handleCalenderIcon();
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space")
                    handleCalenderIcon();
                }}
              />
              <label className="dueDate_activityTimeline">
                {dueDate ? dateString : ""}
              </label>
            </div>

            {dueDate && (
              <CloseIcon
                tabIndex={0}
                title="Cancel"
                onClick={() => {
                  setDueDate(null);
                }}
                onKeyDown={(e) => {
                  (e.key === 'Enter') && setDueDate(null);
                }}
                fill="#000"
              />
            )}
          </div>
          <div style={{ display: isShowDate ? "block" : "none" }}>
            {isShowDate && (
              <DatePicker
                date={dueDate}
                onChange={(value: Date) => {
                  handleCalenderChange(value);
                }}
              />
            )}
          </div>
          <br />
          <label className="weightning">Weighting (optional)</label>
          <br />
          <input
            type="text"
            id="weighting"
            defaultValue={weighting}
            name="weighting"
            className="form-control"
            onInput={(event: any) => {
              if (event.target.value === undefined) {
                setWeighting("");
                return;
              }
              setWeighting(event.target.value);
            }}
          />
          <br />
          <label className="link">Link (optional)</label>
          <br />
          <input
            type="text"
            id="link"
            name="link"
            defaultValue={link}
            className="form-control"
            onInput={(event: any) => {
              if (event.target.value.trim().length === 0) {
                setLink("");
                return;
              }
              setIsValid(true);
              setLink(event.target.value);
            }}
          />
          {!isValid && link && (
            <small className="text-danger">Please enter a valid link</small>
          )}
          <div className="delete_And_Save_Button_Activity_Timeline">
            {props.panelItemId && (
              <button
                className="activity_Timeline_delete"
                onClick={() => handleDelete()}
              >
                Delete Activity
              </button>
            )}

            <button
              className="activity_Timeline_save"
              disabled={isDisabled}
              type="submit"
              onClick={saveData}
            >
              Save
            </button>
          </div>
        </div>
        <div className="description_And_Checkbox">
          <label className="description_Activity_Timeline">
            Description (200 characters){" "}
            <sup>
              <b>*</b>
            </sup>
          </label>
          <textarea
            ref={descriptionInputRef}
            style={descriptionInputStyle}
            defaultValue={description}
            id="description"
            className="description"
            onInput={(event: any) => {
              handleDescriptionInputChange(event);
            }}
          />
          <label
            style={{
              display: openAlert ? "block" : "none",
              color: "#FF0000",
            }}
          >
            Maximum characters for description is 200.
          </label>
          <div className="mark_as_important_Checkbox">
            <label className="container_exam_orientaion">
              Mark as Important (turn icons to red)
              <input
                type="checkbox"
                checked={isImportant}
                onChange={() => setIsImportant(!isImportant)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space")
                    setIsImportant(!isImportant);
                }}
              ></input>
              <span className="checkmark_check"></span>
            </label>
          </div>
        </div>
      </div>
      <DialogConfirmation
        title={"Delete Activity"}
        description={"Are you sure to delete this activity?"}
        handleClose={handlePanelClose}
        open={openDialog}
      />
    </div>
  );
};

export default AddingActivityTimeline;
