export const userState = {
  user_id: "0",
  user_name: "",
  roles: [""],
  account_role: false,
  course_id: "",
  course_role: "",
  expires_in: "",
  access_token_issued_time: "",
};

export const UserReducer = (state, action) => {
  switch (action.type) {
    case "STORE_USER_DATA":
      return {
        ...state,
        user_id: action.payload.user_id,
        roles: action.payload.roles,
        user_name: action.payload.user_name,
        course_id: action.payload.course_id,
        course_role: action.payload.course_role,
        account_role: action.payload.account_role,
        expires_in: action.payload.expires_in,
        access_token_issued_time: action.payload.access_token_issued_time,
      };
    default:
      return state;
  }
};
