import React from "react";
//UQ provided Icons
import { ReactComponent as AquisitionIcon } from "../pages/icons/TimelineIcons/acquisition.svg";
import { ReactComponent as ActivityIcon } from "../pages/icons/TimelineIcons/activity.svg";
import { ReactComponent as AlertIcon } from "../pages/icons/TimelineIcons/alert.svg";
import { ReactComponent as AnalyseIcon } from "../pages/icons/TimelineIcons/analyse.svg";
import { ReactComponent as CollaborationIcon } from "../pages/icons/TimelineIcons/collaboration.svg";
import { ReactComponent as ConstructIcon } from "../pages/icons/TimelineIcons/construct.svg";
import { ReactComponent as CoordinationIcon } from "../pages/icons/TimelineIcons/coordination.svg";
import { ReactComponent as DiscussionIcon } from "../pages/icons/TimelineIcons/discussion.svg";
import { ReactComponent as ExperimentIcon } from "../pages/icons/TimelineIcons/experiment.svg";
import { ReactComponent as InquiryIcon } from "../pages/icons/TimelineIcons/inquiry.svg";
import { ReactComponent as OralIcon } from "../pages/icons/TimelineIcons/oral.svg";
import { ReactComponent as PeerIcon } from "../pages/icons/TimelineIcons/peer.svg";
import { ReactComponent as PerformanceIcon } from "../pages/icons/TimelineIcons/performance.svg";
import { ReactComponent as PortfolioIcon } from "../pages/icons/TimelineIcons/portfolio.svg";
import { ReactComponent as PracticalIcon } from "../pages/icons/TimelineIcons/practical.svg";
import { ReactComponent as PracticeIcon } from "../pages/icons/TimelineIcons/practice.svg";
import { ReactComponent as ProductIcon } from "../pages/icons/TimelineIcons/product.svg";
import { ReactComponent as ReflectIcon } from "../pages/icons/TimelineIcons/reflect.svg";
import { ReactComponent as ResearchIcon } from "../pages/icons/TimelineIcons/research.svg";
import { ReactComponent as TravelIcon } from "../pages/icons/TimelineIcons/travel.svg";
import { ReactComponent as TranslateIcon } from "../pages/icons/TimelineIcons/translate.svg";
import { ReactComponent as WrittenIcon } from "../pages/icons/TimelineIcons/written.svg";

const iconArray: { name: string; iconName: React.FC }[] = [
  { name: "acquisition", iconName: AquisitionIcon },
  { name: "inquiry", iconName: InquiryIcon },
  { name: "discussion", iconName: DiscussionIcon },
  { name: "practice", iconName: PracticeIcon },
  { name: "collaboration", iconName: CollaborationIcon },
  { name: "written", iconName: WrittenIcon },
  { name: "oral", iconName: OralIcon },
  { name: "practical", iconName: PracticalIcon },
  { name: "product", iconName: ProductIcon },
  { name: "portfolio", iconName: PortfolioIcon },
  { name: "peer", iconName: PeerIcon },
  { name: "research", iconName: ResearchIcon },
  { name: "performance", iconName: PerformanceIcon },
  { name: "analyse", iconName: AnalyseIcon },
  { name: "reflect", iconName: ReflectIcon },
  { name: "experiment", iconName: ExperimentIcon },
  { name: "translate", iconName: TranslateIcon},
  { name: "construct", iconName: ConstructIcon },
  { name: "travel", iconName: TravelIcon },
  { name: "alert", iconName: AlertIcon },
  { name: "coordination", iconName: CoordinationIcon },
  { name: "activity", iconName: ActivityIcon },
];

export default iconArray;
