import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { InsertLink } from "../interfaces/insertLink";
import { isValidUrl } from "../utilities/validators";
// import ReactSelect from "./reactSelect";
import { StoreContext } from "../data/store";
import axios from "../axios/config";
import MultiLevelSelect from "./reactMultiLevelSelect";
import "../../src/styles/insertLinkModal.scss";
import { getSession } from "../utilities/querySession";

const InsertLinkModal = (props: InsertLink) => {
  const [selectedLinkType, setSelectedLinkType] = useState("External link");
  const [selectedLinkOption, setSelectedLinkOption] = useState("");
  const [isValid, setIsValid] = useState(true);
  const externalLinkInput = useRef<HTMLInputElement>(null);
  const [courseFiles, setCourseFiles] = useState([]);
  const [courseLinks, setCourseLinks] = useState([]);
  const { state } = useContext<any>(StoreContext);

  const user = useMemo(() => {
    return state?.userState ?? {};
  }, [state]);

  const fetchCourseFiles = async () => {
    const response = await axios.get(
      `/api/instructor/getCourseFiles?userId=${user.user_id}&courseId=${user.course_id}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      },
    );
    setCourseFiles(response.data);
  };

  const fetchCourseLinks = async () => {
    const response = await axios.get(
      `/api/instructor/getCourseLinks?userId=${user.user_id}&courseId=${user.course_id}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      },
    );
    setCourseLinks(response.data);
  };

  useEffect(() => {
    fetchCourseFiles();
    fetchCourseLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkTypeSelection = (event: any) => {
    setSelectedLinkOption("");
    setSelectedLinkType(event.target.value);
    setIsValid(true);
  };
  const handleClose = (status: boolean) => {
    props.handleClose(status);
    setSelectedLinkType("External link");
    setSelectedLinkOption("");
    setIsValid(true);
  };
  const handleCourseFileAndLinkSelection = (value: string) => {
    setIsValid(true);
    setSelectedLinkOption(value);
  };
  const handleApplyLink = () => {
    let link;
    switch (selectedLinkType) {
      case "External link":
        link = externalLinkInput?.current?.value;
        break;
      case "Course link":
        link = selectedLinkOption;
        props.onChangeChloeImported(false);
        break;
      case "Course file":
        link = selectedLinkOption;
        break;
      default:
        break;
    }
    const isValid = isValidUrl(link as string);
    setIsValid(isValid);
    if (isValid) {
      props.applyLink(link as string, selectedLinkType);
      setSelectedLinkType("External link");
    }
  };
  return (
    <Modal
      className="insert-link-modal"
      show={props.show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Modal.Header
        closeLabel={"Close"}
        className="d-flex justify-content-between"
      >
        <Modal.Title>Insert Link</Modal.Title>
        <button
          type="button"
          title="Close"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleClose(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-start">
          <small className="m-1 choose_link_type">Choose link type</small>
          <div className="flex-fill">
            <Form.Select
              aria-label="Default select example"
              size="sm"
              className="w-75 link_dropdown"
              value={selectedLinkType}
              onChange={(e: any) => handleLinkTypeSelection(e)}
            >
              <option value="External link">External link</option>
              <option value="Course link">Course link</option>
              <option value="Course file">Course file</option>
            </Form.Select>
          </div>
        </div>
        <div
          style={{
            display: selectedLinkType === "External link" ? "block" : "none",
          }}
        >
          <input
            type="text"
            className="form-control form-control-sm mt-2"
            placeholder="External link source"
            ref={externalLinkInput}
            onInput={() => setIsValid(true)}
          />
          {!isValid && (
            <small className="text-danger">Please enter a valid link</small>
          )}
        </div>
        <div
          style={{
            display: selectedLinkType === "Course link" ? "flex" : "none",
          }}
        >
          <div
            className={
              isValid
                ? "m-1 multilevel_select d-flex"
                : "m-1 error multilevel_select d-flex"
            }
          >
            <MultiLevelSelect
              type={"link"}
              handleCourseFileAndLinkSelection={
                handleCourseFileAndLinkSelection
              }
              links={courseLinks}
            />

            {!isValid && (
              <small className="text-danger">Please enter a valid link</small>
            )}

            {/* once course link api is done, need to pass courseLinks data instead of courseFiles to the above react select*/}
          </div>
        </div>
        <div
          style={{
            display: selectedLinkType === "Course file" ? "block" : "none",
          }}
        >
          <div className={isValid ? "m-1" : "m-1 error"}>
            <MultiLevelSelect
              type={"file"}
              handleCourseFileAndLinkSelection={
                handleCourseFileAndLinkSelection
              }
              links={courseFiles}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          title="Cancel"
          variant="none"
          className="cancel-insert-link-button"
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
        <Button
          title="Apply"
          variant="none"
          className="apply-button"
          size="sm"
          onClick={handleApplyLink}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InsertLinkModal;
