import React, { useEffect, useState } from "react";
import axios from "../axios/config";
import { Modal } from "react-bootstrap";
import "../styles/courseCopyModal.scss";
import Select, { components } from "react-select";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import AlertWindow from "../components/Snackbar";
import { AlertColor } from "@mui/material";
import { DialogConfirmation } from "../pages/Instructor/dialog";
import { getSession } from "../utilities/querySession";

const CourseCopyModal = (props: any) => {
  const [open, setOpen] = useState(props.showCourseCopyModal);
  const [courseList, setCourseList] = useState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [popUpAlertOpen, setPopUpAlertOpen] = useState<boolean>(false);
  const [popUpAlert, setPopUpAlert] = useState<{
    context?: string;
    severity?: AlertColor;
  }>({ context: "", severity: "success" });

  const POPUP_TITLE = "Are you sure?";
  const POPUP_DESCRIPTION = "Are you sure you want to import the CHLOE data?";
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [enforceFocus, setEnforceFocus] = useState<boolean>(true);

  const customStyles = {
    container: (base: any, state: any) => ({
      ...base,
      borderRadius: "4px",
      opacity: "1",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #707070",
      "&:hover": {
        borderColor: "black",
      },
      "&:focus-within": {
        borderColor: "black",
      },
      width: "298px",
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: "none",
    }),

    control: (base: any, state: any) => ({
      ...base,
      boxShadow: 0,
      border: 0,
      borderRadius: 0,
      minHeight: "auto",
      marginTop: "2px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isSelected ? "#black" : "black",
      backgroundColor: "none",
      "&:hover": {
        fontWeight: "bold",
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "black",
      "&:hover": {
        color: "black",
      },
    }),
    menu: (base: any, state: any) => ({
      ...base,
      padding: 0,
      boxShadow: 0,
      borderRadius: "4px",
      border: "1px solid #4F2378",
    }),
  };

  const CaretDownIcon = () => {
    library.add(faCaretDown);
    return <FontAwesomeIcon icon="caret-down" />;
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const handlePanelClose = async (result: Boolean) => {
    if (result) {
      // setOpenPanel(false);
      await performCopy();
    }
    setEnforceFocus(true);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (props.copyCourseList) {
      setSelectedCourse(null);
      setOpen(props.showCourseCopyModal);
      let temp: any[] = [];
      props.copyCourseList.map((course: any) => {
        temp.push({
          value: course.course_name,
          label: course.course_name,
          course_id: course.bb_course_id,
        });
      });
      setCourseList([...temp]);
    }
  }, [props]);

  const handleAlertClose = () => {
    setPopUpAlertOpen(false);
  };

  const handleClose = (refreshPage: boolean) => {
    setOpen(false);
    props.onChange(refreshPage);
  };

  const handleChange = (e: any) => {
    setSelectedCourse(e.course_id);
  };

  const performCopy = async () => {
    try {
      const courseData = {
        selected_course_id: selectedCourse,
        user: props.user.user_id,
        course_id: props.user.course_id,
      };
      const panelData = (await axios.put(`/api/instructor/copyCourse`, courseData, {
        headers: {
          Authorization: getSession('token')
        }
      }))
        .data;
      handleClose(true);
    } catch (err) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `error`,
      });
      setPopUpAlertOpen(true);
    }
  };

  return (
    <div>
      <AlertWindow
        popUpAlertOpen={popUpAlertOpen}
        handleAlertClose={handleAlertClose}
        popUpAlert={popUpAlert}
      />
      <Modal
        show={open}
        onHide={() => handleClose(false)}
        backdrop="static"
        className="modal"
        enforceFocus={enforceFocus}
      >
        <Modal.Header closeButton>
          <Modal.Title>Copy Course</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="body">
            <label>
              Select course learning pathway to copy it to current course
            </label>

            {courseList && (
              <Select
                className="dropdown"
                styles={customStyles}
                components={{ DropdownIndicator }}
                options={courseList}
                isSearchable={false}
                onChange={handleChange}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_button" onClick={() => handleClose(false)}>
            Cancel
          </button>
          <button
            disabled={selectedCourse !== null ? false : true}
            className="confirm_button"
            onClick={() => {
              if (openPanel === false) {
                setEnforceFocus(false);
                setOpenPanel(true);
              }
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      <DialogConfirmation
        title={POPUP_TITLE}
        description={POPUP_DESCRIPTION}
        handleClose={handlePanelClose}
        open={openPanel}
      />
    </div>
  );
};

export default CourseCopyModal;
