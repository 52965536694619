import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { propsInterface } from "../interfaces/routes";

const AppRouter = (props: { routeData: propsInterface[] }) => {
  return (
    <BrowserRouter>
      <Routes>
        {props.routeData.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>404</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
