const buildDate = (date?: Date) => {
  if (date) {
    return (
      (new Date(date!).getDate() < 10
        ? "0" + new Date(date!).getDate()
        : +new Date(date!).getDate()) +
      "-" +
      (new Date(date!).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(date!).getMonth() + 1) +
      "-" +
      new Date(date!).getFullYear()
    );
  }
  return null;
};

export default buildDate;
