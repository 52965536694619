import axios from "axios";

export const baseURL = (env: any) => {
  console.log("this is the environment variable:",env);
  switch (env) {
    case "sandbox":
      return "https://chloe-sandbox-alb.uq.bb-icm-hosting.net"; //sandbox url
    case "dev2":
      return "https://chloe-dev2-alb.uq.bb-icm-hosting.net"; //dev2 url
    case "prod1":
      return "https://chloe-prod1.uq.bb-icm-hosting.net"; //prod1 url
    default:
      return "https://dev.chloe.com:9000/";
  }
};

const axiosConfig = axios.create({
  baseURL: baseURL(process.env.REACT_APP_APP_ENV),
  withCredentials: true,
});

export default axiosConfig;
