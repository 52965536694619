import React from "react";
import AppRoutes from "./AppRoutes";
import Redirection from "../pages/Redirection";
import Instructor from "../pages/Instructor/instructor.component";
import Learner from "../pages/Learner/learner.component";
import HealthCheck from "../healthCheck";
import { config } from "../interfaces/routes";

const pconfig: config[] = [
  {
    path: "/",
    component: Redirection,
  },
  {
    path: "/instructor",
    component: Instructor,
  },
  {
    path: "/learner",
    component: Learner,
  },
  {
    path: "/healthCheck",
    component: HealthCheck,
  },
];

const Routes = () => {
  return <AppRoutes routeData={pconfig} />;
};

export default Routes;
