/* eslint-disable @typescript-eslint/no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Routes from "./routes/routes";
function App() {
  return (
    <div className="App">
            <Routes />
    </div>
  );
}

export default App;
