import axios from "./config";
import { getSession } from "../utilities/querySession";

export const getLearningJourney = async (course_id: string) => {
    const learningJourneyPayload = await axios.get(
      `/api/learner/journey?course_id=${course_id}`, {
        headers: {
          Authorization: getSession('token')
        }
      }
    );
    return learningJourneyPayload.data;
  };
 export const getCourseActivity =async (course_id: string) => {
    const courseActivityTimelineData = await axios.get(
      `/api/instructor/getCourseActivity?course_id=${course_id}`, {
        headers: {
          Authorization: getSession('token')
        }
      }
    );  
    return courseActivityTimelineData.data;
  };