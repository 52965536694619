import React, { FC } from "react";

const HealthCheck: FC<{} | undefined> = () => {
  return (
    <>
      <div>Ok</div>
    </>
  );
};

export default HealthCheck;
