import React, { FC, useState } from "react";
import { ReactComponent as AddIcon2 } from "../pages/icons/addIcon2.svg";
import Tooltip from "@mui/material/Tooltip";
import iconArray from "./ActivityIconPack";
import {
  CourseActivityItemInterface,
  IconStyleInterface,
} from "../interfaces/CourseActivity_Interfaces";
import AlertWindow from "../components/Snackbar";
import { AlertColor } from "@mui/material";

export const CourseActivityItem: FC<CourseActivityItemInterface> = (props) => {
  const iconStyle: IconStyleInterface = {
    color: "black",
    stroke: "black",
    height: "30px",
    width: "30px",
    fill: "none",
  };
  const importantIconStyle: IconStyleInterface = {
    color: "#FF0000",
    stroke: "#FF0000",
    fill: "none",
    height: "30px",
    width: "30px",
  };

  const [popUpAlertOpen, setPopUpAlertOpen] = useState<boolean>(false);
  const popUpAlert: {
    context?: string;
    severity?: AlertColor;
  } = { context: "Cannot add more than 4 activities", severity: "error" };

  const handleActivityDialog = (panelItemId?: number) => {
    if (props.panelItems.length >= 4 && panelItemId === undefined) {
      setPopUpAlertOpen(true);
      return;
    }
    props.onChange(props.panel_id, !props.openActivityDialog, panelItemId);
  };

  const handleAlertClose = () => {
    setPopUpAlertOpen(false);
  };

  return (
    <div className="parent_weak_box_2">
      <AlertWindow
        popUpAlertOpen={popUpAlertOpen}
        handleAlertClose={handleAlertClose}
        popUpAlert={popUpAlert}
      />
      <div className="weak__box__2">
        <div className="topic__2">{props.title}</div>
        <div className="box__2">
          {props.panelItems.map((data: any, key) => {
            return (
              <>
                {iconArray.map((icon) => {
                  if (data.activity_type === icon.name) {
                    return (
                      <Tooltip
                        title={`${
                          icon.name.charAt(0).toUpperCase() + icon.name.slice(1)
                        }`}
                        key={`tooltip_${key}`}
                        // className="cursor"
                      >
                        <div
                          className="svg_Activity_Timeline"
                          style={
                            data.is_important ? importantIconStyle : iconStyle
                          }
                          id={data.panel_item_id}
                          key={`svg_${key}`}
                          onClick={() => {
                            handleActivityDialog(data.panel_item_id);
                          }}
                          tabIndex={0}
                          onKeyDown={(e)=>{
                            if (e.key === "Enter" || e.key === "Space") {
                              handleActivityDialog(data.panel_item_id);
                            }
                          }}
                        >
                          <icon.iconName />
                        </div>
                      </Tooltip>
                    );
                  }
                })}
              </>
            );
          })}
          <button
            className="button__activity"
            onClick={() => handleActivityDialog()}
          >
            <AddIcon2 />
          </button>
        </div>
      </div>
    </div>
  );
};
