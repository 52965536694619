/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useRef, useState, useEffect } from "react";
import ColorPicker from "./Color-picker";
import DatePicker from "../components/datepicker";
import { ReactComponent as SaveIcon } from "../pages/icons/saveIcon.svg";
import { ReactComponent as EditIcon } from "../pages/icons/editIcon.svg";
import { ReactComponent as CalendarIcon } from "../pages/icons/calendarIcon.svg";
import { Item } from "../interfaces/learningPathConfig";
import Switch from "@mui/material/Switch";
import { green } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";

const LearningComp: FC<Item> = (props) => {
  const [selectColor, setColor] = useState<string>(props?.color ?? "#51247A");
  const [selectDate, setDate] = useState<Date>(props?.date ?? new Date());
  const [isShowColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [isShowDate, setisShowDate] = useState<boolean>(false);
  const [isDisabled, setDisabler] = useState<boolean>(props.isDisabled);
  const [input, setInput] = useState<string>(props.value);
  const inputConfig = useRef<HTMLInputElement>(null);
  const panel = useRef<HTMLDivElement>(null);
  const inputContainer = useRef<HTMLDivElement>(null);
  const editIcon = useRef<SVGSVGElement>(null);
  const saveIcon = useRef<SVGSVGElement>(null);
  const [showCalender, setShowCalender] = useState<boolean>(
    props?.showCalender
  );

  const [isPublished, setIsPublished] = useState<boolean>(
    props?.isPublished ? props?.isPublished : false,
  );

  useEffect(() => {
    /**
     * Handle Click Outside the Panel Element
     */

    if (props.value.length === 0 || props.value === "") {
      setDisabler(false);
      inputConfig.current?.focus();
    }
    if (isDisabled === false) {
      inputConfig.current?.focus();
      editIcon.current?.classList.add("hidden");
      saveIcon.current?.classList.remove("hidden");

      document.getElementsByTagName("body")[0].classList.add("ptr_none");
    } else {
      inputConfig.current!.scrollLeft = 0;
      inputContainer.current?.classList.remove("error");
      editIcon.current?.classList.remove("hidden");
      saveIcon.current?.classList.add("hidden");
      document.getElementsByTagName("body")[0].classList.remove("ptr_none");
    }
    if (document.activeElement !== inputConfig.current) {
      editIcon.current?.classList.remove("hidden");
      saveIcon.current?.classList.add("hidden");
      setDisabler(true);
    }

    function handleClickOutside(event: any) {
      if (panel.current && !panel.current.contains(event.target)) {
        if (isDisabled) {
          panel.current?.classList.remove("selected");
          setShowColorPicker(false);
          document
            .getElementById(`color_picker_${props?.id}`)
            ?.classList.remove("borders");
        } else {
          props.showSavetoProceedError();
          event.preventDefault();
          inputConfig.current?.focus();          
        }
        if (isShowDate) {
          setisShowDate((isShowDate) => !isShowDate);
        }
      } else if (!inputContainer.current?.contains(event.target)) {
        if (isDisabled) {
          inputConfig.current?.blur();
        } else {
          props.showSavetoProceedError();
          event.preventDefault();
          inputConfig.current?.focus();
          
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [panel, props, isDisabled, isShowDate]);

  useEffect(() => {
    setDate(props.date ?? new Date());
  }, [props.date]);

  useEffect(() => {
    setShowCalender(props.showCalender);
  }, [props.showCalender]);

  const toggle_textboxfocus = () => {
    if (inputConfig.current?.value.trim().length === 0) {
      inputConfig.current?.focus();
      inputContainer.current?.classList.add("error");
    } else if (isDisabled === true) {
      inputContainer.current?.classList.remove("error");
      editIcon.current?.classList.toggle("hidden");
      saveIcon.current?.classList.toggle("hidden");
    } else {
      inputConfig.current?.focus();
    }
  };

  const toggle_colorpicker = () => {
    setShowColorPicker((isShowColorPicker) => !isShowColorPicker);
    console.log(isShowColorPicker);
    document
      .getElementById(`color_picker_${props?.id}`)
      ?.classList.toggle("borders");
  };
  const close_colorpicker = () => {
    setShowColorPicker(false);
    document
      .getElementById(`color_picker_${props?.id}`)
      ?.classList.remove("borders");
  };

  const handleCalenderIcon = () => {
    setisShowDate((isShowDate) => !isShowDate);
  };

  const handleCalenderChange = (value: Date) => {
    setDate((date) => {
      return value;
    });
    props.onChange("date", value, props?.id);
    setisShowDate((isShowDate) => !isShowDate);
  };

  const handlePublishStatus = () => {
    setIsPublished(!isPublished);
    props.onChange("status", !isPublished, props?.id);
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  }));

  return (
    <>
      <div className="learning__config-panel">
        <div
          ref={panel}
          className="learning__config-edit-panel"
          onClick={() => {
            panel.current?.classList.add("selected");
          }}
        >
          <div
            ref={inputContainer}
            className={
              isDisabled
                ? "learning_config-title"
                : "learning_config-title-border"
            }
          >
            <div
              className={isDisabled ? "learning__config-head" : "hidden"}
              onClick={() => {
                if (
                  !props.activeWeekConfig &&
                  !isShowDate &&
                  !isShowColorPicker
                ) {
                  setDisabler(false);
                  props.onActive(true);
                  toggle_textboxfocus();
                }
              }}
            >
              {input}
              <EditIcon
                title="Edit"
                ref={editIcon}
                id={"learning__config-edit_icon_" + props?.id}
                className="learning__config_edit_icon"
                onClick={() => {
                  if (
                    !props.activeWeekConfig &&
                    !isShowDate &&
                    !isShowColorPicker
                  ) {
                    setDisabler(false);
                    props.onActive(true);
                    toggle_textboxfocus();
                  }
                }}
                tabIndex={0}
                onKeyDown={(e: any) => {
                  if (
                    !props.activeWeekConfig &&
                    !isShowDate &&
                    !isShowColorPicker &&
                    (e.key === "Enter" || e.key === "Space")
                  ) {
                    setDisabler(false);
                    props.onActive(true);
                    toggle_textboxfocus();
                  }
                }}
              />
            </div>

            <div
              className={
                !isDisabled ? "ptr_all learning__config-head" : "hidden"
              }
            >
              <input
                ref={inputConfig}
                name={"learning__config-head_" + props?.id}
                id={"learning__config-head_" + props?.id}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (inputConfig.current?.value.trim() !== "") {
                      setInput(inputConfig.current?.value.trim() ?? "");
                      props?.onChange(
                        "input",
                        inputConfig.current?.value.trim(),
                        props?.id
                      );
                      setDisabler(true);
                      props.onActive(false);
                      inputConfig.current?.blur();
                    } else {
                      inputContainer.current?.classList.add("error");
                      inputConfig.current.value =
                        inputConfig.current?.value.trim();
                      inputConfig.current?.focus();
                    }
                  } else {
                    //Do nothing
                  }
                }}
                onFocus={toggle_textboxfocus}
                onInput={(e) => {
                  inputContainer.current?.classList.remove("error");
                  setInput(inputConfig.current?.value ?? "");
                }}
                type="text"
                autoComplete="off"
                value={input}
              />
              <SaveIcon
                title="Save"
                className="learning__config-edit_icon ptr_all"
                ref={saveIcon}
                id={`learning__config-save_icon_${props?.id}`}
                onClick={() => {
                  if (inputConfig.current?.value.trim() !== "") {
                    setInput(inputConfig.current?.value.trim() ?? "");
                    props?.onChange(
                      "input",
                      inputConfig.current?.value.trim(),
                      props?.id
                    );
                    setDisabler(true);
                    props.onActive(false);
                  } else {
                    inputContainer.current?.classList.add("error");
                    inputConfig.current.value =
                      inputConfig.current?.value.trim();
                    inputConfig.current?.focus();
                  }
                }}
              />
            </div>
          </div>
          <div
            id={"color_picker_" + props?.id}
            className="learning_config-color-picker"
            style={{ backgroundColor: selectColor }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Space") {
                if (
                  document.activeElement !==
                    document.querySelector("input:focus") &&
                  !isShowDate
                ) {
                  console.log("this")
                  toggle_colorpicker();
                }
              }
            }}
            onClick={() => {
              if (
                document.activeElement !==
                document.querySelector("input:focus") &&
                !isShowDate
              ) {
                console.log('not this');
                toggle_colorpicker();
              }
            }}
          >
            <div style={{ display: isShowColorPicker ? "" : "none" }} onClick={()=>{
              toggle_colorpicker();
            }}>
              <ColorPicker
                className={"color-picker"}
                id={props?.id}
                color={selectColor}
                onChange={(color: string) => {
                  props?.onChange("color", color, props?.id);
                  setColor(color);
                  // console.log("here");
                  // close_colorpicker();
                  panel.current?.classList.remove("selected");
                }}
              />
            </div>
          </div>
          <div
            className="learning_config-date-picker"
            style={{
              display: showCalender ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <CalendarIcon
              className="calender__icon"
              width={45}
              height={20}
              tabIndex={0}
              onKeyDown={(e) => {
                if (
                  document.activeElement !==
                    document.querySelector("input:focus") &&
                  !isShowColorPicker &&
                  (e.key === "Enter" || e.key === "Space")
                ) {
                  handleCalenderIcon();
                }
              }}
              onClick={() => {
                if (
                  document.activeElement !==
                  document.querySelector("input:focus") &&
                  !isShowColorPicker
                ) {
                  handleCalenderIcon();
                }
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ display: isShowDate ? "block" : "none" }}>
            {showCalender
              ? isShowDate && (
                <DatePicker
                  date={selectDate}
                  onChange={(value: Date) => {
                    handleCalenderChange(value);
                  }}
                />
              )
              : null}
          </div>
          <div
            className="learning_config-date"
            style={{ display: showCalender ? "block" : "none" }}
          >
            {(selectDate.getDate() < 10 ? "0" : "") + selectDate.getDate()} -{" "}
            {(selectDate.getMonth() + 1 < 10 ? "0" : "") +
              (selectDate.getMonth() + 1)}{" "}
            - {selectDate.getFullYear()}
          </div>
          <GreenSwitch
            checked={isPublished}
            onChange={handlePublishStatus}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
    </>
  );
};

export default LearningComp;
