import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { FC } from "react";
import { SnackBarData } from "../interfaces/snackBarInterface";

const AlertWindow: FC<SnackBarData> = (props: any) => {
  return (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.popUpAlertOpen}
      onClose={props.handleAlertClose}
      key={"al"}
    >
      <Alert severity={props.popUpAlert.severity}>
        {props.popUpAlert.context}
      </Alert>
    </Snackbar>
  );
};

export default AlertWindow;
