/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useContext, useEffect } from "react";
import Redirect from "./Redirect";
import axios from "../axios/config";
// import Loader from "../components/loader";
import { StoreContext } from "../data/store";
import { UserPayloadResponse } from "../interfaces/redirections";
import { useLocation } from "react-router-dom";
import { setSession } from "../utilities/querySession";

const Redirection: FC<unknown> = () => {
  const { dispatch } = useContext<any>(StoreContext);
  const [userData, setUserData] = useState<UserPayloadResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("userData");
  const redirect = query.get("redirect")

  const fetchUserData = async () => {
    try {
      setSession('token', token!)
      const userPayload = await axios.get("/lti/userData", {
        headers: { "x-user-data": token! },
      });
      setUserData(userPayload.data);
      dispatch({
        type: "STORE_USER_DATA",
        payload: {
          user_id: userPayload.data.user_id,
          user_name: userPayload.data.user_name,
          roles: userPayload.data.roles,
          account_role: userPayload.data.account_role,
          course_id: userPayload.data.course_id,
          course_role: userPayload.data.course_role,
          expires_in: userPayload.data.expires_in,
          access_token_issued_time: userPayload.data.access_token_issued_time,
        },
      });
      setIsLoading(false);
    } catch (err: any) {
      setUserData({ msg: err.message });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (redirect) {
      window.location.href = decodeURIComponent(redirect);
    } else {
      fetchUserData();
    }
  }, []);
  return (
    <>
      {!isLoading ? (
        userData?.msg ? (
          <h5>{userData?.msg}</h5>
        ) : (
          <Redirect data={userData} />
        )
      ) : (
        <h5>Please wait until redirection...</h5>
      )}
    </>
  );
};

export default Redirection;
