/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, FC, useState, useEffect, Ref, RefObject } from "react";

interface Props {
  className: string | undefined;
  id: number | undefined;
  color: string;
  onChange: Function;
}

const Color_picker: FC<Props> = memo(({ className, id, color, onChange }) => {
  const COLORS = [
    "#51247A",
    "#E62645",
    "#2EA836",
    "#BB9D65",
    "#EB602B",
    "#FBB800",
    "#4085C6",
    "#00A2C7",
  ];

  const listColors = COLORS.map((colors) =>
    colors === color ? (
      <li
        key={colors}
        tabIndex={0}
        className="list-group-item swatch selected"
        style={{
          backgroundColor: colors,
          padding: "0px",
        }}
        onClick={() => {
          onChange(colors);
        }}
        onKeyDown={(e) => {
          if (e.key === "Space" || e.key === "Enter") onChange(colors);
        }}
      >
        <input
          className="swatch-input"
          type="radio"
          defaultChecked
          value={colors}
          name={"color-selected_" + id}
        />
      </li>
    ) : (
      <li
        tabIndex={0}
        key={colors}
        className="list-group-item swatch"
        style={{
          backgroundColor: colors,
          borderRadius: "2px",
          padding: "0px",
        }}
        onClick={() => {
          onChange(colors);
        }}
        onKeyDown={(e) => {
          if (e.key === "Space" || e.key === "Enter") onChange(colors);
        }}
      >
        <input
          className="swatch-input"
          type="radio"
          value={colors}
          name={"color-selected_" + id}
        />
      </li>
    )
  );
  return (
    <ul className={className} id={"color_picker_pallete_" + id}>
      {listColors}
    </ul>
  );
});

export default Color_picker;
