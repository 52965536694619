/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Navigate as RouterRedirect } from "react-router-dom";
import { Redirection } from "../interfaces/redirections";
import { isIncludes } from "../utilities/validators";

const Redirect = (props: any) => {
  let pageView;

  if (
    props.data.roles.includes("Administrator") ||
    props.data.roles.includes("Instructor") ||
    props.data.roles.includes("ContentDeveloper")
  ) {
    pageView = "instructor";
  } else if (props.data.roles.includes("Learner")) {
    pageView = "learner";
  }
  switch (pageView) {
    case "instructor":
      return (
        <RouterRedirect
          to={{
            pathname: "/instructor",
          }}
        />
      );
    case "learner":
      return (
        <RouterRedirect
          to={{
            pathname: "/learner",
          }}
        />
      );
    default:
      return <h1>Invalid Role</h1>;
  }
};

export default Redirect;
