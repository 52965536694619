import React, { useContext, useEffect, useMemo, useState, FC } from "react";
import axios from "../axios/config";
import { getSession } from "../utilities/querySession";
import { StoreContext } from "../data/store";
const Announcement: FC<unknown> = () => {
  const { state } = useContext<any>(StoreContext);
  const [courseAnnouncements, setCourseAnnouncements] = useState([]);

  const user = useMemo(() => {
    return state?.userState ?? {};
  }, [state]);

  const fetchCourseAnnouncements = async () => {
    const response = await axios.get(
      `/api/instructor/getAnnouncements?userId=${user.user_id}&courseId=${user.course_id}`, {
        headers: {
          Authorization: getSession('token')
        }
      }
    );
    setCourseAnnouncements(response.data);
  };
  useEffect(() => {
    fetchCourseAnnouncements();
  }, []);
  const listItems = courseAnnouncements.slice(0, 6).map((data: any) => (
    <li className="assessment__item list-group-item" key={data.id} id={data.id}>
      <div className="assessment-item__data">
        <div className="assessment-item__date text-truncate margin-block-none">
          {data.title}
        </div>
        <p className="announcement-item__title  margin-block-none">
        {data.modified}
        </p>
      </div>
      {/* <div className='assessment-item__data'>
        <div className='assessment-item__date'>{data.date.toDateString()}</div>
        <p className='assessment-item__title text-truncate'>{data.title}</p>
      </div> */}
    </li>
  ));
  return (
    <>
      <div className="Assessment" role={"banner"}>
        <div className="assessment__head d-flex justify-content-between align-items-center" role={"group"}>
          <h2 className="ms-2 Assessment-h2">Announcement feed</h2>
        </div>
        <ul className="Assessment___list list-group">{listItems}</ul>
      </div>
    </>
  );
};

export default Announcement;
