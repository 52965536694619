import { Modal } from "react-bootstrap";
import "../styles/courseCopyModal.scss";
import { FC } from "react";
import { TimerModalInterface } from "../interfaces/TimerModalInterface";

const TimerModal: FC<TimerModalInterface> = (props) => {
  return (
    <Modal show={props.showTimerModal} className="modal">
      <Modal.Header>
        <Modal.Title>Session expired. {props.content}</Modal.Title>
      </Modal.Header>
    </Modal>
  );
};

export default TimerModal;
