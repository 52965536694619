import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "../axios/config";
import { CourseAssessment } from "../interfaces/courseAssessment";
import { StoreContext } from "../data/store";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, AlertColor, CircularProgress, Stack } from "@mui/material";

import { getSession } from "../utilities/querySession";

const CustomizedTables = () => {
  const { state } = useContext<any>(StoreContext);
  const [courseAssessments, setCourseAssessments] = useState([]);
  const [courseChildren, setCourseChildren] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [popUpAlertOpen, setPopUpAlertOpen] = useState<boolean>(false);
  const [popUpAlert, setPopUpAlert] = useState<{
    context?: string;
    severity?: AlertColor;
  }>({ context: "", severity: "success" });

  const user = useMemo(() => {
    return state?.userState ?? {};
  }, [state]);

  const fetchCourseAssessments = async () => {
    const response = await axios.get(
      `/api/instructor/getCourseAssessments?userId=${user.user_id}&courseId=${user.course_id}&courseRole=${user.course_role}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      },
    );
    setCourseAssessments(response.data);
  };

  const childCourses = async () => {
    const response = await axios.get(
      `/api/instructor/getCourseChildren?userId=${user.user_id}&courseId=${user.course_id}`,
      {
        headers: {
          Authorization: getSession("token"),
        },
      },
    );
    const allCourses = response.data;
    allCourses.unshift({
      course_name: user.course_role,
      course_id: user.course_id,
    });
    setCourseChildren(response.data);
  };
  useEffect(() => {
    fetchCourseAssessments();
    childCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#51247a",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleChange = async (
    assessment: string,
    course_id: string,
    event: any,
  ) => {
    try {
      const caData: CourseAssessment = {
        course_id: course_id,
        assessment_id: assessment,
        status: event.target.checked,
      };
      await axios.put(`/api/instructor/courseAssessment`, caData, {
        headers: {
          Authorization: getSession("token"),
        },
      });
      fetchCourseAssessments();
    } catch (e) {
      setPopUpAlert({
        context: `An error occurred. Please try again later!`,
        severity: `error`,
      });
      setPopUpAlertOpen(true);
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>List of Assessments</StyledTableCell>
            {courseChildren.map((course: any, index: number) => {
              const courseName = course.course_name.split("(")[0] ?? course.course_name;
              const courseType = course.course_name.split("(")[1]
              if (courseType !== undefined) {
                return (
                  <StyledTableCell align="center" key={`courseChildren_${index}`}>
                    {courseName}<br></br>{"(" + courseType}
                  </StyledTableCell>
                );
              } else {
                return (
                  <StyledTableCell align="center" key={`courseChildren_${index}`}>
                    {courseName}
                  </StyledTableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {courseAssessments.map((row: any, index: number) => (
            <StyledTableRow key={`Assesment_${row.title}_${index}`}>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              {row.courseAssessments.map((caRow: any, index: number) => (
                <StyledTableCell align="center" key={`cell_${index}`}>
                  <Checkbox
                    checked={caRow.status}
                    className="form-check-input"
                    onChange={(event) =>
                      handleChange(caRow.assessment_id, caRow.course_id, event)
                    }
                    {...label}
                  ></Checkbox>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomizedTables;
